<template>
  <v-card class="elevation-1" tile>
    <v-card-text>
      <validation-observer slim v-slot="{ handleSubmit }">
        <v-row>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'email')"
              :label="$t('hr.employee.contact.email')"
              rules="email|max:255"
              :index="0"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.email"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 280px;"
                  counter="255"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'secondaryPhone')"
              :label="$t('hr.employee.contact.secondary_phone')"
              rules="phone"
              :index="1"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <phone-picker
                  v-model="employeePayload.secondaryPhone"
                  outlined
                  color="tertiary"
                  style="max-width: 400px;"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'fixedPhone')"
              :label="$t('hr.employee.contact.fixed_phone')"
              rules="phone"
              :index="2"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <phone-picker
                  v-model="employeePayload.fixedPhone"
                  outlined
                  color="tertiary"
                  style="max-width: 400px;"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'workPhone')"
              :label="$t('hr.employee.contact.work_phone')"
              rules="phone"
              :index="3"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <phone-picker
                  v-model="employeePayload.workPhone"
                  outlined
                  color="tertiary"
                  style="max-width: 400px;"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'address')"
              :label="$t('hr.employee.contact.address')"
              rules="max:512"
              :index="4"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.address"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 600px;"
                  counter="512"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'spousePhone')"
              :label="$t('hr.employee.contact.spouse_phone')"
              rules="phone"
              :index="5"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <phone-picker
                  v-model="employeePayload.spousePhone"
                  outlined
                  color="tertiary"
                  style="max-width: 400px;"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'extensionNumber')"
              :label="$t('hr.employee.contact.extension_number')"
              :index="6"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.extensionNumber"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'emergencyContactName')"
              :label="$t('hr.employee.contact.emergency_contact_name')"
              rules="max:255"
              :index="7"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <v-text-field
                  v-model="employeePayload.emergencyContactName"
                  outlined
                  dense
                  color="tertiary"
                  style="max-width: 200px;"
                  counter="255"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <v-col cols="12" class="py-0">
            <profile-field
              :value="$helpers.get(employee, 'emergencyContactPhone')"
              :label="$t('hr.employee.contact.emergency_contact_phone')"
              rules="phone"
              :index="8"
              :active-index="activeIndex"
              :saving="saving"
              :loading="loading"
              :is-editable="isEditable"
              @click:edit="onEditClick"
              @click:save="handleSubmit(onSaveClick)"
              @click:cancel="onCancelClick"
            >
              <template v-slot="{ errors }">
                <phone-picker
                  v-model="employeePayload.emergencyContactPhone"
                  outlined
                  color="tertiary"
                  style="max-width: 400px;"
                  :error-messages="errors"
                />
              </template>
            </profile-field>
          </v-col>
          <template v-for="(objectAttributeValue, idx) in employeePayload.objectAttributeValues">
            <v-col :key="idx" cols="12" class="py-0">
              <profile-field
                :value="objectAttributeValue.value"
                :label="objectAttributeValue.name"
                :index="9 + idx"
                :active-index="activeIndex"
                :saving="saving"
                :loading="loading"
                :is-editable="isEditable"
                @click:edit="onEditClick"
                @click:save="handleSubmit(onSaveClick)"
                @click:cancel="onCancelClick"
              >
                <template v-slot="{ errors }">
                  <v-text-field
                    v-model="objectAttributeValue.value"
                    outlined
                    dense
                    color="tertiary"
                    style="max-width: 400px;"
                    :error-messages="errors"
                  />
                </template>
              </profile-field>
            </v-col>
          </template>
        </v-row>
      </validation-observer>
    </v-card-text>
  </v-card>
</template>

<script>
  import { EMPLOYEE_CONTACT } from "../query";

  export default {
    name: "EmployeeContact",
    props: {
      profileSettings: Object
    },
    components: {
      PhonePicker: () => import("@/components/global/PhonePicker"),
      ProfileField: () => import("./components/ProfileField")
    },
    data: () => ({
      loading: false,
      employeePayload: {
        email: null,
        secondaryPhone: null,
        fixedPhone: null,
        workPhone: null,
        address: null,
        spousePhone: null,
        extensionNumber: null,
        emergencyContactName: null,
        emergencyContactPhone: null,
        objectAttributeValues: []
      },
      employee: null,
      activeIndex: -1,
      saving: false
    }),
    computed: {
      isEditable() {
        return this.$helpers.get(this.profileSettings, this.$enums.PROFILE_SETTINGS.CONTACT);
      }
    },
    methods: {
      onSaveClick() {
        this.saving = true;
        this.$api.employeeService
          .updateContactCredentials(this.$store.state.auth.user.id, this.employeePayload)
          .then(response => {
            if (response.status === 200) {
              this.activeIndex = -1;
              this.fetchEmployeeContact();
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.saving = false));
      },
      onCancelClick() {
        this.activeIndex = -1;
        this.setEmployeePayload(this.employee);
      },
      onEditClick(index) {
        this.activeIndex = index;
      },
      setEmployeePayload(employee) {
        this.employeePayload = { ...employee };
        this.employeePayload.objectAttributeValues = this.$helpers.cloneDeep(employee.objectAttributeValues);
      },
      async fetchEmployeeContact() {
        this.loading = true;
        this.$apollo
          .query({
            query: EMPLOYEE_CONTACT,
            variables: {
              id: this.$store.state.auth.user.id
            }
          })
          .then(({ data, errors }) => {
            if (!data.error && !errors) {
              this.employee = data.employee.contact;
              this.setEmployeePayload(data.employee.contact);
            }
          })
          .catch(error => this.$helpers.showNotification(error.message))
          .finally(() => (this.loading = false));
      }
    },
    created() {
      this.fetchEmployeeContact();
    }
  };
</script>
